<template>
  <form class="form" @submit.prevent="submit">
    <div class="dialog-body">
      <p style="color: red;">{{errorText}}</p>

      <div class="text">{{$t('components.transfer.toCard.desc')}}</div>
      <br>
      <Dropdown
          v-model="model.cardNumberFrom"
          :options="fromCards"
          :state="!validation.hasError('model.cardNumberFrom')"
          :error-text="validation.firstError('model.cardNumberFrom')"
          :placeholder="$t('components.transfer.toCard.labelCardNumberFrom')"
      ></Dropdown>
      <br><br>
      <Input
          type="number"
          v-model="model.amount"
          :label="$t('components.transfer.toCard.labelAmount')"
          :state="!validation.hasError('model.amount')"
          :error-text="validation.firstError('model.amount')"
          :requred="true"
      ></Input>
    </div>
    <div class="dialog-footer">
      <div class="dialog__btns">
        <Button type="button" @click="$emit('cancel')">Отмена</Button>
        <Button visual-style="fill" :loading="isLoading">Продолжить</Button>
      </div>
    </div>
  </form>
</template>

<script>
import {mapGetters} from "vuex";
import SimpleVueValidator from 'simple-vue-validator';
import ApiService from "@/services/api.service";

const Validator = SimpleVueValidator.Validator;

export default {
  props:['cardNumberTo'],
  components: {
  },
  data() {
    return {
      model:{
        cardNumberFrom:null,
        amount:null
      },
      errorText:'',
      submitted:false,
      isLoading:false
    }
  },
  computed:{
    ...mapGetters({
      cards: "cards/cards"
    }),
    fromCards(){

      let options = [];
      for(let card of this.cards){
        if(card.Id!=this.cardNumberTo){
          options.push({
            label:card.Number+' '+card.MoneyBalance+' тнг.',
            value:card.Id
          });
        }
      }

      return options;
    }
  },
  methods:{
    submit(){

      this.submitted = true;

      this.$validate().then(success => {
        if (success) {

          this.isLoading=true;

          ApiService.transfer({
            cardNumberFrom: this.model.cardNumberFrom,
            cardNumberTo:this.cardNumberTo,
            amount: this.model.amount
          }).then((resp)=>{
            this.$emit('success',resp.data);
            this.isLoading=false;
          }).catch((err)=>{
            let data = err.data;
            this.errorText = data.errorText;
            this.isLoading=false;
          });

        }
      })
    },
    getCardById(id) {

      for (let card of this.cards) {
        if (card.Id == id)
          return card;
      }

      return null;
    }
  },
  created() {
    this.model.value=this.value;
  },
  validators: {
    "model.cardNumberFrom": function (value) {
      if (this.submitted)
          return Validator.value(value).required(this.$t('errors.required'));
    },
    "model.amount": function (value) {
      if (this.submitted)
        return Validator.value(value).required(this.$t('errors.required')).custom( () => {

          if(value==0)
            return this.$t('errors.required');

          let card = this.getCardById(this.model.cardNumberFrom);

          if(card){

            let balance = parseFloat(card.MoneyBalance);

            if(value>balance)
              return this.$t('errors.balance');
          }
      });
    }
  },
  mixins: [SimpleVueValidator.mixin]
}
</script>
