<template>
  <div class="profile__cards-info-content">
    <div class="profile-title">{{$t('cards.transfer.title')}}</div>
    <div class="profile__cards-info-subtitle">
      {{$t('cards.transfer.desc')}}
    </div>
    <div class="profile__cards-info-card">
      <div class="profile__cards-info-card-img">
        <img v-if="card.CardImageUrl" :src="card.CardImageUrl">
        <svg v-else width="264" height="166" viewBox="0 0 354 222" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="354" height="222" fill="#E9F7D6"/>
        </svg>
      </div>
      <div class="profile__cards-info-card-right">
        <div class="profile__cards-info-card-title">{{card.CardType}}</div>
        <div class="profile__cards-info-card-subtitle">{{$t('cards.transfer.selectedCard')}}</div>
        <div class="profile__cards-info-card-btns">
          <button class="btn btn__primary btn__primary_fill" @click="openModalTransferToCard=true">{{$t('cards.transfer.toCard')}}</button>
          <button class="btn btn__primary btn__primary_fill" @click="openModalTransferFromCard=true">{{$t('cards.transfer.fromCard')}}</button>
        </div>
      </div>
    </div>
    <Dialog
        buttonsType="save"
        v-if="openModalTransferToCard"
        @close="openModalTransferToCard = false"
    >
      <div slot="header">
        <h3>{{$t('cards.transfer.modalToCard')}}</h3>
      </div>
      <div slot="body">
        <TransferToCard
            :cardNumberTo="card.Id"
            @cancel="openModalTransferToCard=false"
            @success="()=>{openModalTransferToCard=false;updateInfo()}"
        ></TransferToCard>
      </div>
    </Dialog>
    <Dialog
        buttonsType="save"
        v-if="openModalTransferFromCard"
        @close="openModalTransferFromCard = false"
    >
      <div slot="header">
        <h3>{{$t('cards.transfer.modalFromCard')}}</h3>
      </div>
      <div slot="body">
        <TransferFromCard
            :cardNumberFrom="card.Id"
            @cancel="openModalTransferFromCard=false"
            @success="()=>{openModalTransferFromCard=false;updateInfo()}"
        ></TransferFromCard>
      </div>
    </Dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import Dialog from '@/components/Controlls/Dialog';

import TransferToCard from "@/components/cards/TransferToCard";
import TransferFromCard from "@/components/cards/TransferFromCard";

export default {
  props:['number'],
  components: {
    Dialog,
    TransferToCard,
    TransferFromCard
  },
  data() {
    return {
      openModalTransferToCard:false,
      openModalTransferFromCard:false,
      isLoading:false,
      selected:null
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      cards: "cards/cards"
    }),
    card(){
      for(let card of this.cards){
        if(card.Number == this.number)
          return card;
      }
      return null;
    }
  },
  methods:{
    ...mapMutations({
      setStatusLoading: "setStatusLoading",
      ...mapActions({
        'getCards':'cards/getCards'
      })
    }),
    updateInfo(){
      this.isLoading=true;
      this.getCards().then(()=>{
        this.isLoading=false;
      });
    }
  }
}
</script>
